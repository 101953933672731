import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Advent of Code Day 22 – Sporifica Virus",
  "description": "",
  "date": "2017-12-22T08:00:00.000Z",
  "published": "2017-12-22T08:00:00.000Z",
  "image": ""
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <lite-youtube {...{
      "videoid": "prdKoJ5B5z8",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`For Day 22, we built a virus and found that Safari is much faster than Chrome.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The virus carrier works in bursts; in each burst, it wakes up, does some work, and goes back to sleep. The following steps are all executed in order one time each burst:`}</p>
    </blockquote>
    <ol>
      <li parentName="ol">{`Turn left if current cell is infected, turn right if it isn't.`}</li>
      <li parentName="ol">{`Flip the cell (clean –> infected, infected –> clean)`}</li>
      <li parentName="ol">{`Move forward`}</li>
    </ol>
    <blockquote>
      <p parentName="blockquote">{`Diagnostics have also provided a map of the node infection status (your puzzle input). The virus carrier begins in the middle of the map facing up.`}</p>
    </blockquote>
    <p>{`Naturally, I decided to solve this puzzle in React. Because it sounds like it might draw something interesting. And who doesn't want to see a visualization of the spread of a deadly virus?`}</p>
    <p>{`It draws a dick. 😑`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`Generative dick art in 32,400 divs. Enjoy `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/944147617520353282/pu/img/tHhLA0G-sJTsYikH.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/944147668535730176"
          }}>{`10:08:40 AM – 12/22/2017`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=944147668535730176",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`3`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/944147668535730176",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`1`}</a></div>{`
    `}</div></div>
    <p>{`And takes 5 minutes to run. And gets the wrong result.`}</p>
    <p><strong parentName="p">{`Edit:`}</strong>{` I mixed up left and right. When you fix left and right, it no longer draws a dick, and it still gets the wrong answer 🤷‍♀️. `}<strong parentName="p">{`/Edit`}</strong></p>
    <p>{`There must be an off-by-one error somewhere in the code that I can't find. The animation looks correct.`}</p>
    <p>{`The image evolves, the virus carrier seems to follow the rules, and there are no obvious digressions from the plan. The recursive pattern it falls into around step 7,000 looks suspicious but not out of the question 🤔`}</p>
    <h2 {...{
      "id": "an-attempt-was-made-and-then-fixed"
    }}>{`An attempt was made (and then fixed)`}</h2>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/advent-of-code-2017/blob/master/22/src/Day22.js"
      }}>{`see the code on GitHub`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "https://swizec.com/blog/wp-content/uploads/2017/12/image.png",
        "alt": null
      }}></img></p>
    <p>{`It's built from 3 React components: `}<inlineCode parentName="p">{`GridRow`}</inlineCode>{`, which renders a single row of the grid, `}<inlineCode parentName="p">{`Grid`}</inlineCode>{`, which renders the grid, and `}<inlineCode parentName="p">{`Day22`}</inlineCode>{`, which drives the whole thing.`}</p>
    <h3 {...{
      "id": "gridrow"
    }}>{`GridRow`}</h3>
    <p>{`If you guessed `}<em parentName="p">{`"Oh, that should be simple"`}</em>{`, you were right.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const GridRow = ({ row, x, y, ry }) =>
    row.map((cell, i) => (
        <div style={{ background: y == ry && x == i ? "red" : cell == "#" "black" "white", width: "5px", height: display: "inline-block" }} key={i}>
             
        </div>
    ));
`}</code></pre>
    <p>{`Loop through a row, which is an array, and render a `}<inlineCode parentName="p">{`<div>`}</inlineCode>{` for each `}<inlineCode parentName="p">{`cell`}</inlineCode>{`. Cells are 5x5 pixels and their background can be `}<inlineCode parentName="p">{`red`}</inlineCode>{`, `}<inlineCode parentName="p">{`black`}</inlineCode>{` or `}<inlineCode parentName="p">{`white`}</inlineCode>{`.`}</p>
    <p>{`Red if the virus carrier is currently on that cell, black if the cell is infected, white if it's not.`}</p>
    <h3 {...{
      "id": "grid"
    }}>{`Grid`}</h3>
    <p>{`Once more a simple one, walk through an array of rows, render `}<inlineCode parentName="p">{`GridRow`}</inlineCode>{` components.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Grid = ({ grid, x, y }) =>
  grid.map((row, i) => (
    <div key={i} style={{ display: "flex", justifycontent: "center" }}>
      <gridrow row={row} x={x} y={y} ry={i}></gridrow>
    </div>
  ));
`}</code></pre>
    <h3 {...{
      "id": "day-22--the-actual-solution"
    }}>{`Day 22 – the actual solution`}</h3>
    <p><inlineCode parentName="p">{`<Day22>`}</inlineCode>{` is where all the logic happens. It sets up our grid as data, drives the virus carrier, and renders `}<inlineCode parentName="p">{`<Grid>`}</inlineCode>{` and some meta data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const Width = 180,
  offset = 23;

class Day22 extends Component {
  state = {
    grid: new Array(Width).fill(".").map((_, i) => {
      let row =
        i > Math.floor(Width / 2 - input.length / 2 - offset)
          ? input[i - Math.floor(Width / 2 - input.length / 2 - offset)]
          : null;

      let a = new Array(Width).fill(".");
      if (row) {
        a.splice(Width / 2 - row.length / 2 + offset, row.length, ...row);
      }
      return a;
    }),
    x: Math.floor(Width / 2) + offset,
    y: Math.floor(Width / 2) - offset,
    vx: 0,
    vy: -1,
    bursts: 0,
    infected: 0,
  };

  componentDidMount() {
    this.burstActivity();
  }

  burstActivity() {
    // snip
  }

  turnLeft(vx, vy) {
    // snip
  }

  turnRight(vx, vy) {
    // snip
  }

  render() {
    const { grid, width, bursts, x, y, infected } = this.state;

    return (
      <div>
        <h3 style={{ display: "flex", justifycontent: "center" }}>
          bursts: {bursts}, pos: ({x}, {y}), infected: {infected}
        </h3>
        <div style={{ border: "1px solid red" }}>
          <grid grid={grid} x={x} y={y}></grid>
        </div>
      </div>
    );
  }
}
`}</code></pre>
    <p>{`That `}<inlineCode parentName="p">{`state`}</inlineCode>{` calculation looks hairy. We're taking an array of `}<inlineCode parentName="p">{`Width`}</inlineCode>{`, filling it with healthy cells, `}<inlineCode parentName="p">{`.`}</inlineCode>{`, then walking through it to build `}<inlineCode parentName="p">{`row`}</inlineCode>{`s. If the current index is past a certain point, we replace the middle part of the `}<inlineCode parentName="p">{`row`}</inlineCode>{` with a row from our input array. We do that with a `}<inlineCode parentName="p">{`splice`}</inlineCode>{`.`}</p>
    <p>{`The result is a 180x180 grid of cells. Some of them are infected, but most aren't.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`render`}</inlineCode>{` method just renders all of this.`}</p>
    <h3 {...{
      "id": "the-virus-carrier-logic"
    }}>{`The virus carrier logic`}</h3>
    <p>{`The virus carrier logic comes in 3 functions. `}<inlineCode parentName="p">{`burstActivity`}</inlineCode>{`, `}<inlineCode parentName="p">{`turnLeft`}</inlineCode>{` and `}<inlineCode parentName="p">{`turnRight`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`   burstActivity() {
        const { x, y, bursts, grid } = this.state;
        let { vx, vy, infected } = this.state;

        if (grid[y][x] === "#") {
            grid[y][x] = ".";

            [vx, vy] = this.turnRight(vx, vy);
        } else {
            grid[y][x] = "#";
            infected += 1;

            [vx, vy] = this.turnLeft(vx, vy);
        }

        this.setState({
            x: x + vx,
            y: y + vy,
            vx,
            vy,
            grid,
            infected,
            bursts: bursts + 1
        });
        if (bursts + 1 < 10000) {
            requestAnimationFrame(() => this.burstActivity());
        }
    }

    turnLeft(vx, vy) {
        let _vx = -1 * vy;
        vy = vx;
        return [_vx, vy];
    }

    turnRight(vx, vy) {
        let _vx = vy;
        vy = -1 * vx;
        return [_vx, vy];
    }
`}</code></pre>
    <p><inlineCode parentName="p">{`burstActivity`}</inlineCode>{` is our main logic driver. It looks at the current virus carrier position, turns left if it's infected, or turns right if it isn't. In both cases, it also flips the cell.`}</p>
    <p>{`If the cell was flipped to infected, we increment our solution counter of how many cells we've infected.`}</p>
    <p>{`Then it updates state and continues the loop if there have been fewer than 10,000 bursts of activity.`}</p>
    <p><inlineCode parentName="p">{`turnLeft`}</inlineCode>{` and `}<inlineCode parentName="p">{`turnRight`}</inlineCode>{` turn the direction our virus carrier is going. Left or right using a little bit of vector maths. Thanks to a helpful stream watcher who showed me this better way. I had a huge sequence of ifs at first 🙈`}</p>
    <h2 {...{
      "id": "lessons-learned"
    }}>{`Lessons learned`}</h2>
    <p>{`I learned a couple of lessons with this build.`}</p>
    <ol>
      <li parentName="ol">{`React is SO MUCH FASTER after you turn off streaming`}</li>
      <li parentName="ol">{`If you render 250,000 DOM nodes, you're gonna have a bad time`}</li>
      <li parentName="ol">{`Pegging your algorithm at `}<inlineCode parentName="li">{`requestAnimationFrame`}</inlineCode>{` makes it slow`}</li>
      <li parentName="ol">{`Safari is `}<em parentName="li">{`a lot faster`}</em>{` than Chrome at re-rendering a few ten thousand DOM nodes with React. I don't know why`}</li>
    </ol>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`It's surprising how much faster than Chrome Safari is at re-rendering a 180x180 div array in React on every requestAnimationFrame`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`I triedFirefox too but it was laughably slow. Yes the new super fast Firefox.`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`Maybe I should switch to Safari 🤔 `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/944241576779292673/pu/img/N7NB4BtULbq1b6oW.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/944241764029816832"
          }}>{`4:22:34 PM – 12/22/2017`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=944241764029816832",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`10`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/944241764029816832",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`5`}</a></div>{`
    `}</div></div>
    <ol>
      <li parentName="ol">{`When your code runs slow, development is slow, and getting the wrong result after 2+ hours of tinkering kinda sucks`}</li>
      <li parentName="ol"><inlineCode parentName="li">{`array.splice(5, undefined, 1,2,3,4,5)`}</inlineCode>{` doesn't even bat an eye, turns that `}<inlineCode parentName="li">{`undefined`}</inlineCode>{` into `}<inlineCode parentName="li">{`0`}</inlineCode>{` and proceeds like nothing happened`}</li>
    </ol>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`This "works" `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/hashtag/javascript"
          }}>{`#javascript`}</a>{` `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/media/DRoy0XAU8AAjkkx.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/944122955876900866"
          }}>{`8:30:28 AM – 12/22/2017`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=944122955876900866",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`3`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/944122955876900866",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`2`}</a></div>{`
    `}</div></div>
    <p>{`Thanks to stream watchers for pointing out that I can't spell `}<inlineCode parentName="p">{`row.length`}</inlineCode>{`. You da real MVPs.`}</p>
    <p><img parentName="p" {...{
        "src": "/88bd1158ad07a2facb1215d9384afa33/media-l0MYvHFJkgm1fnuko-giphy.gif",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      